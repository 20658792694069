@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@font-face {
  font-family: "Girloy";
  src: url("../fonts/Gilroy-Regular.woff2")
    format("woff2"),
  url("../fonts/Gilroy-Regular.woff")
    format("woff");
    font-weight: normal;
}
@font-face {
  font-family: "Girloy";
  src: url("../fonts/Gilroy-Semibold.woff2")
    format("woff2"),
  url("../fonts/Gilroy-Semibold.woff")
    format("woff");
    font-weight: 600;
}
@layer base {
  :root {
    --black: black;
    --primary: #11E372;
    --green: #13D16A;
    --border-checkbox: rgba(0,0,0, .16);
    --border-radio: #D6D6D6;
    --gap: 16px;
    --border-grey: #C7D0D6;
    --width-container: 390px;
    --gradient: linear-gradient(180deg, #24E57C 0%, #13D16A 100%);
    --gray: #F2F2F2;
    --progress: #F4F6F8;
    --family: "Urbanist", sans-serif;
    --familyGirloy: "Girloy";
    --familyInter: "Inter", sans-serif;
    --btn-shadow: 0px 16px 24px 0px rgba(19, 209, 106,0.36);
    --large-shadow: 0px 32px 63px 0px rgba(19, 209, 106,0.16);
    --back-shadow: 0px 4px 4px 0px rgba(0, 0, 0,0.25);
    --green-shadow: 6px 14px 32px 0px rgba(19, 209, 106,0.16);
    --close-shadow: 0px 1px 7px 0px rgba(66, 19, 122,0.2);
    --popup-shadow: 0px 10px 30px 0px rgba(151, 165, 174 ,0.22);
    --dark: #222222;
    --light: #737373;
    --border-light: #b9b9bb45;
    --mark: rgb(255, 0, 0);
    --danger: #FEF0EF;
    --dangerStroke:#EA898E;
    --warning: #FCF4E9;
    --warningStroke: #F1DBAA;
    --good: rgba(58, 174, 146, 0.12);
    --goodStroke: rgba(58, 174, 146, 0.4);
    --check-btn: #F8F8F8;
    --check-border: #D2E1EC;
    --darkOpacity: rgba(34, 34, 34, 0.32);
    --grayB: #B8B8B8;
    --grayA1: #A1A1A1;
    --red: #D93A52;
    --lightBlue: rgba(174, 194, 208, 0.5);
    --plan: #E32323;
    --grayE: #EBEBEB;
    --blue: #4695DD;
    --green2: #1BDA72;
    --swiper-pagination-top: 300px;

  }
  .betterme{
    --black: black;
    --primary: #EA749B;
    --green: #EA749B;
    --green2: #EA749B;
    --border-checkbox: rgba(0,0,0, .16);
    --border-radio: #D6D6D6;
    --contentInversePrimaryColor: #1717178f;
    --gap: 16px;
    --border-grey: #C7D0D6;
    --width-container: 390px;
    --gradient: linear-gradient(180deg, #EA749B 0%, #EA749B 100%);
    --gray: #eff1f4;
    --progress: #F4F6F8;
    --family: "Urbanist", sans-serif;
    --btn-shadow: 0px 0px 0px 0px rgba(19, 209, 105, 0);
    --large-shadow: 0px 32px 63px 0px rgba(19, 209, 106,0.16);
    --back-shadow: 0px 4px 4px 0px rgba(0, 0, 0,0.25);
    --green-shadow: 6px 14px 32px 0px rgba(19, 209, 106,0.16);
    --close-shadow: 0px 1px 7px 0px rgba(66, 19, 122,0.2);
    --popup-shadow: 0px 10px 30px 0px rgba(151, 165, 174 ,0.22);
    --dark: #171717;
    --light: #737373;
    --border-light: #EFF1F4;
    --mark: rgb(255, 0, 0);
    --check-btn: #F8F8F8;
    --check-border: #b8b8b8;
    --darkOpacity: rgba(34, 34, 34, 0.32);
    --grayB: #F6F7F9;
    --grayA1: #A1A1A1;
    --red: #D93A52;
    --lightBlue: rgba(174, 194, 208, 0.5);
    --plan: #EA749B;
    --grayE: #EBEBEB;
    --blue: #4695DD;
    --danger: rgba(238, 80, 90, 0.12);
    --dangerStroke:rgba(250, 45, 45, 0.6);
    --warning: rgba(233, 164, 63, 0.12);
    --warningStroke: rgba(233, 164, 63, 0.6);
    --good: #E8F6F1;
    --goodStroke: #B2D8CB;
  }
}

@layer base {
  body {
    @apply text-dark h-screen w-full overflow-hidden;
  }
  h1 {
    @apply text-xl3 font-medium text-dark;
  }
  .betterme h1:not(.checkout-h1) {
    @apply text-xl45 font-semibold ;
  }
  h2 {
    @apply text-dark pt-4 mb-6 font-bold;
    mark {
      @apply text-green bg-transparent;
    }
  }
  p {
    @apply text-base;
  }
  label {
    @apply text-base block mb-3 mt-5 font-semibold;

  }
  #root {
    @apply flex max-w-content mx-auto  w-full h-full;
  }
}

* {
  scroll-behavior: smooth;
}

.spinner{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height : 50px;
    width : 50px;
    border-radius : 50%;
    border : 10px solid #F4F6F8;
    border-top-color : #11E372;
    animation : spin 1s linear infinite;
  }

  @keyframes spin{
    0%{
      transform :translate(-50%, -50%) rotate(0deg);
    }
    100%{
      transform :translate(-50%, -50%) rotate(360deg);
    }
  }

html {
  font-family: var(--family);
  color: black;
  overflow: hidden;
  height: 100%;
  .betterme {
    font-family: var(--familyInter);

  }
}
body {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    display: none;
  }
}
.move-burger .burger{
  right: 16px;
}
.betterme {
  .move-burger .burger{
    right: 20px;
  }
}
.lifestyle {
  &:nth-child(2) {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}
.bmi-transition {
  transition: .8s .6s transform, 1s 1.4s left;
  span {
    animation: scaleUp 1s 2.4s forwards;
  }
}

.graph-transition {
  @media (max-height: 770px) {
    margin-top: -100px;

  }
  .circle {
    top: 39.5%;
    left: 8.5%;
   box-shadow: 0 0 0 8px rgba(241, 11, 46, .16);
   z-index: 5;
  }
  .circle-green {
    top: 70%;
    left: 72.3%;
   box-shadow: 0 0 0 8px rgba(19, 209, 106, .16);
   z-index: 5;
   transform: scale(0);
  }
  .day {
    top: 82%;
    left: 52%;

  }
  .today {
    top: 82%;
    left: 5.5%;
    opacity: 0;
  }
  .data {
    top: 82%;
    left: 69%;
    opacity: 0;
  }
  .graph {
    width: 0;
  }
  .before, .after {
    opacity: 0;
    &:before {
      position: absolute;
      content: '';
      bottom: -8px;
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 12px 8.5px 0 8.5px;
      border-color: #13D16A transparent transparent transparent;
      transform: translateX(-50%) rotate(0deg);
      left: 50%;
    }
  }
  .before{
    top: 26%;
    z-index: 2;
    left: 4%;
    transition: .5s .8s  opacity, .5s .8s top;
    &:before {
      position: absolute;
      content: '';
      bottom: -8px;
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 12px 8.5px 0 8.5px;
      border-color: #D93A52 transparent transparent transparent;
      transform: rotate(0deg);
      left: 17px;
    }
  }
  .after {
    top: 56%;
    z-index: 2;
    right: 16%;
    transition: .5s 1.5s  opacity, .5s 1.5s top;
    @media (max-width: 375px) {
      right: 15%;

    }
  }
}
.graph-upload:not(.graph-version2) {
   .circle {
    transition: .5s .5s all;
    transform: scale(1);
  }
  .circle-green {
    transition: .5s 1.5s all;
    transform: scale(1);
  }
  .day {
    top: 82%;

  }
  .today {
    top: 84%;
    opacity: 1;
    transition: .5s .5s all;
  }
  .data {
    top: 84%;
    opacity: 1;
    transition: .5s 1.5s all;
  }
  .before{
    top: 23%;
    animation: 1s .5s scaleShake forwards ease;
    opacity: 1;
  }
  .after {
    top: 53%;
    animation: 1s 1.3s scaleShake forwards ease;
    opacity: 1;
    transition: .5s 1.5s  opacity, .5s 1.5s top;
  }
  .graph {
    transition: 2s all ease-in-out;
    width: 390px;
  }
}
.profile-image {
  height: 390px;
}
.h-graph {
  & + p {
    @media (max-height: 770px) {
      margin-top: -40px;

    }
  }

}
.added-top {
  @media (max-height: 700px) {
    margin-top: -50px;

  }

}
.graph {
  @media (max-height: 680px) {
    margin-top: -20px;

  }
  & + div {
    @media (max-height: 680px) {
      margin-top: -70px;

    }
  }

}
.graph-version {
  & + .added-margin {
    @media (max-height: 700px) {
      margin-top: -30px;
      position: relative;
      z-index: 2;

    }
  }

}
.graph-version2 {
  .circle,
  .circle-green {
    transform: scale(1);
    z-index: 11;
  }
  .circle {
    top: 34.5%;
  }
  .circle-green {
    top: 67.5%;
    left: 73%;
  }
  .circle-gray {
    top: 67.5%;
    left: 73%;
    opacity: 0;
    z-index: 11;
    box-shadow: 0 0 0 8px rgba(161, 161, 161, .16);
  }
  & + .added-margin {
    margin-top: -20px;
    @media (max-height: 700px) {
      margin-top: -70px;
      position: relative;
      z-index: 2;

    }
  }
  .graph-transition {
    margin-top: -20px;
    @media (max-height: 770px) {
      margin-top: -60px;

    }
    .red-line {
      top: 37%;
      left: 10%;
    }
    .green-line {
      top: 70%;
      left: 75%;
    }
    .gray-line {
      top: 70%;
      left: 75%;
      opacity: 0;
    }
    .today {
      top: 82%;
      left: 6%;
      opacity: 1;
      z-index: 11;
    }
    .data {
      top: 82%;
      left: 52%;
      color: #11E372;
      opacity: 0;
      z-index: 11;
      &:before {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: calc(100% + 12px);
        height: calc(100% + 8px);
        border-radius: 3px;
        border: 1px solid #13D16A;
      }

    }
    .day {
      top: 82%;
      left: 69%;
      color: #A1A1A1;
      opacity: 1;
      z-index: 11;
      &:before,
      &:after {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%) rotate(27deg);
        width: 110%;
        height: 1px;
        background-color: #E20404;
        opacity: 0;
      }
      &:after {
        transform: translate(-50%,-50%) rotate(-27deg);
      }
    }
    .proceed {
      top: 69.5%;
      left: calc(75% - 70px);
      height: 41px;
      background-color: rgba(175, 227, 78, 0.3);
      z-index: 10;
      width: 70px;
      opacity: 0;

    }
    .before, .after {
      transform: scale(1);
      opacity: 1;
      &:before {
        position: absolute;
        content: '';
        bottom: -8px;
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 12px 8.5px 0 8.5px;
        border-color: #13D16A transparent transparent transparent;
        transform: translateX(-50%) rotate(0deg);
        left: 50%;
      }
    }
    .before{
      top: 18%;
      &:before {
        position: absolute;
        content: '';
        bottom: -8px;
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 12px 8.5px 0 8.5px;
        border-color: #D93A52 transparent transparent transparent;
        transform: rotate(0deg);
        left: 17px;
      }
    }
    .after {
      top: 51%;
      right: 14%;
      z-index: 2;
    }
  }
  & + p {
    opacity: 1;
  }
  .graph {
    width: 390px;
    opacity: 1;
  }
  &.graph-upload {
    .proceed {
      animation: 1s 3s opacityShake forwards ease;
    }
    .green-line {
      left: calc(75% - 70px);
      transition: .7s 1.7s all;
    }
    .circle-green {
      left: calc(73% - 70px);
      transition: .7s 1.7s all;
    }
    .after {
      right: calc(15% + 70px);
      transition: .7s 1s all;
    }
    .circle-gray,
    .gray-line {
      opacity: 1;
      transition: .7s 1.7s all;
    }
    .data {
      opacity: 1;
      transition: .7s 2.4s all;
    }
    .day {
      &:before,
      &:after {
        opacity: 1;
        transition: .7s 1.7s all;
      }
    }
    .image-before {
      opacity: 0;
      transition: .7s 1.7s all;
    }
  }

}

.swiper-pagination {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 18px;
  span {
    width: 6px;
    height: 6px;
    display: block;
    border-radius: 100%;
    margin: 0 3px;
    background-color: #D9D9D9;
  }
  .swiper-pagination-bullet-active {
    background-color: #13D16A;
    opacity: 1 !important;
  }
}
.btn {
  &:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(110deg, rgba(255,255,255,0) 44%, rgba(255,255,255,0.35) 50%, rgba(255,255,255,0) 56%);
    background-size: 350%;
    background-position: center;
    animation: loading 1.3s  ease-in-out infinite;
    z-index: 22;
    transition: 0.3s;
  }
}

.transtion-custom {
  transition: .5s all, 0s .5s top, 0s .5s height, 0s .5s overflow;
}
.progress {
  &:before {
    position: absolute;
    content: '';
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% auto;
  }
  &:last-child {
    &:after {
      position: absolute;
      content: '';
      top: 50%;
      right: 0;
      transform: translate(50%, -50%);
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }

  }
  &:first-child {
    &:before{
      background-image: none;
      background-color: #13D16A;
    }
  }
}

input[type=range] {
  -webkit-appearance: none;
  width: 100%;
  height: 16px;
  background-color: transparent;
  border-radius: 8px;
  pointer-events: none;
}
@media (hover: hover) {
  input[type=range] {
    pointer-events: all;
  }
}

input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-thumb {
  border: 2px solid var(--green);
  height: 22px;
  width: 22px;
  border-radius: 50%;
  background-color: white;
  cursor: pointer;
  -webkit-appearance: none;
}
.range-dots {
  span {
    &:nth-child(1) {
      left: calc(50% / 5 * 4);
      transform: translate(100%, -50%);
    }
    &:nth-child(2) {
      left: calc(50% / 5 * 3);
      transform: translate(75%, -50%);
    }
    &:nth-child(3) {
      left: calc(50% / 5 * 2);
      transform: translate(50%, -50%);
    }
    &:nth-child(4) {
      left: calc(50% / 5);
      transform: translate(25%, -50%);
    }
    &:nth-child(5) {
      right: calc(50% / 5 * 4);
      transform: translate(-100%, -50%);
    }
    &:nth-child(6) {
      right: calc(50% / 5 * 3);
      transform: translate(-75%, -50%);
    }
    &:nth-child(7) {
      right: calc(50% / 5 * 2);
      transform: translate(-50%, -50%);
    }
    &:nth-child(8) {
      right: calc(50% / 5);
      transform: translate(-25%, -50%);
    }
  }
}
.submit{
  opacity: 1 !important;
  box-shadow: none;
}
.submit:disabled{
  cursor: not-allowed;
  opacity: 0.7 !important;
}
.submit.apple-pay{
  background: #000;
}
.submit.paypall{
  color: #000;
  background: #FEC53B;
  span{
    margin-left: 20px;
    text-transform: none;
    font-size: 20px;
    font-weight: 500;
    display: block;
  }
}
.submit.submit-dft{
  span{
    margin-left: 10px;
    text-transform: none;
    font-size: 20px;
    font-weight: 500;
    display: block;
  }
}
.hero-image {
  @media (max-height: 750px) {
      max-width: 250px;
  }
}
.range-image {
    height: 390px;
    @media (max-height: 780px) {
        margin-top: -50px;
        height: 305px;
    }
    img {
      @media (max-height: 780px) {
          max-width: 150px;
      }

    }
}
.body-areas {
  @media (max-height: 780px) {
      margin-top: 30px;
  }
}

.Tab--selected{
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0 0 0 1px #13D16A !important;
}
.Tab{
  border: 1px solid #13D16A !important;
}
.circle {
  top: 18%;
  left: 8%;
 box-shadow: 0 0 0 8px rgba(241, 11, 46, .16);
 transform: scale(0);
}
.circle-green {
  top: 50%;
  left: 58%;
 box-shadow: 0 0 0 8px rgba(19, 209, 106, .16);
}
.weeks {
  left: 0;
  bottom: 25%;
  span {
    opacity: 0;
    transform: scale(0);
    transition: .5s .5s all;
    &:nth-child(2) {
      transition: .5s 1s all;
    }
    &:nth-child(3) {
      transition: .5s 1.5s all;

    }
    &:nth-child(4) {
      transition: .5s 2s all;
    }
  }
}
.without {
  top: 28%;

}
.after {
  top: 43%;

}
.graph-animation {
  .circle {
    transition: .5s .5s all;
    transform: scale(1);
  }
  .graph-image {
    transition: 2s all ease-in-out;
    width: 390px;
  }
  .before {
    animation: .5s 1.3s scale forwards ease;
    opacity: 1;
    transition: .5s .8s  opacity, .5s .8s top;
    left: 4%;
    top: 1%;
    @media (max-width: 376px) {
      left: 3.5%;

    }
    &:before {
      position: absolute;
      content: '';
      bottom: -8px;
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 12px 8.5px 0 8.5px;
      border-color: #D93A52 transparent transparent transparent;
      transform: rotate(0deg);
      left: 17px;
    }
  }
  .without {
    line-height: 1.2;
    top: 25%;
    left: 60%;
    animation: .5s 1.7s scale forwards ease;
    opacity: 1;
    transition: .5s 1.2s  opacity, .5s 1.2s top;

  }
  .after {
    line-height: 1.2;
    top: 40%;
    right: 7%;
    animation: .5s 2s scale forwards ease;
    opacity: 1;
    transition: .5s 1.6s  opacity, .5s 1.6s top;

  }
  .weeks {
    span {
      opacity: 1;
      transform: scale(1);
    }
  }
}

@keyframes loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
      background-position: 0 50%;
  }
}
@keyframes scaleUp {
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes scale {
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes scaleShake {
  65% {
    transform: scale(1);
  }
  70% {
    transform: scale(.9);
  }
  80% {
    transform: scale(.95);
  }
  90% {
    transform: scale(.9);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes opacityShake {
  65% {
    opacity: 1;
  }
  70% {
    opacity: 0.4;
  }
  80% {
    opacity: 1;
  }
  90% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
@keyframes today {
 to {
  opacity: 1;
 }
}
.paddle-frame{
  position: static !important;
}
.couponInformationContainer{
  display: none !important;
}
.active-betterme-graph {
  transition: .6s all;
  opacity: 1;
}

.progress-absolute {
  position: relative;
  margin-top: -35px;
  ul {
    justify-content: center;
  }
  li {
    padding: 0 8px;
    width: 52px;
    height: 2px;

      span {
        height: 2px;
      }
    
  }
}

.testimonials-swiper .swiper-pagination {
  display: none !important;
}

.betterme {
  color: var(--dark);
  .btn {
    font-size: 17px;
    font-family: var(--familyGirloy);
    &::before {
      display: none;
    }
  }
  .spinner{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height : 50px;
      width : 50px;
      border-radius : 50%;
      border : 5px solid #F4F6F8;
      border-top-color : var(--green);
      animation : spin 1s linear infinite;
    }
  .text-timer {
    color: #EA749B;
    span span {
      opacity: 1;
    }
  }
  .swiper-pagination {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 18px;
    span {
      width: 10px;
      height: 10px;
      display: block;
      border-radius: 100%;
      margin: 0 6px !important;
      background-color: #F6F7F9;
      opacity: 1 !important;
    }
    .swiper-pagination-bullet-active {
      background-color: #171717;
      opacity: 1 !important;
    }
  }
 .testimonials-swiper .swiper-pagination {
    display: none !important;
  }
  .progress-absolute {
    position: relative;
    margin-top: -35px;
    ul {
      justify-content: center;
    }
    li {
      padding: 0 2px;
      width: 29px;
      span {
        background-color: rgba(23, 23, 23, 0.06);
        span {
          background-color: rgba(23, 23, 23, 0);
        }
        span.bg-green {
          background-color: #171717;
        }
      }
      &::before {
        display: none;
      }
      &::after {
        display: none;
      }
    }
  }
  .step:not(:last-child) > div {
    &:after {
      display: none;
    }
  }
  .steps:first-child {
    margin-top:  -8px;
  }
  .bmi-transition {
    transition: .8s 1.5s transform, 1s 1s left;
    span {
      animation: opacity 1s 3s forwards;
    }
  }
}
.rdp {
  border-radius: 12px;
  border: 1px solid rgba(23, 23, 23, 0.08);
  margin-top: 16px;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.05);
  z-index: 100;
  background: white;
  position: relative;
  th, td {
    width: 100%;
    button {
      opacity: 1 !important;
      &[aria-selected="true"] {
        color: white;
        background-color: var(--green);
      }
      &:disabled{
        opacity: 0.2 !important;
      }
    }
  }
  th {
    color: rgba(23, 23, 23, 0.56);;
  }
}
.animation-arrow {
  svg {
    animation: opacityArrow 1.2s ease-in infinite;
    animation-delay: 0s;
    transform: translateX(20px);
    opacity: 0;
    &:nth-child(2) {
      animation: opacityArrow 1.2s ease-in infinite;
      animation-delay: 1s;
      transform: translateX(40px);
    }
    &:nth-child(3) {
      animation: opacityArrow 1.2s ease-in infinite;
      animation-delay: 2s;
      transform: translateX(60px);
    }
    &:nth-child(4) {
      animation: opacityArrow 1.2s ease-in infinite;
      animation-delay: 3s;
      transform: translateX(80px);
    }
  }
}


@keyframes translateDelayLodar {
  0% { transform: translateX(0); }
  100% { transform: translateX(-50%); }
}

.translate-after-delayLoader {
  animation: translateDelayLodar 0.5s ease-in-out 5s forwards;
}

.base-graph {
  transition: 2s all;
  &::before {
    position: absolute;
    content: '';
    bottom: -4px;
    right: 0;
    width: 14px;
    height: 14px;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 3px 5px rgba(0, 0, 0, .04), 0 5px 12px rgba(22, 42, 65, .1);
    opacity: 0;
    transition: 1s 2s all;
    z-index: 11;
  }
}
.base-graph-event {
    margin: 0 auto;
    &__wrap{
      display: flex;
      align-items: center;
    }
  

  img{
    width: 24px;
    height: 24px;
    margin-left: 4px;
  }
}
.base-graph--gain  {
  &::before {
    top: -4px;
    bottom: unset;

  }
}
.base-circle-event {
  fill: #fff;
      stroke: none;
      filter: drop-shadow(0 3px 5px rgba(0, 0, 0, 0.04)) drop-shadow(0 5px 12px rgba(22, 42, 65, 0.1));
}
.base-line-event {
    stroke: rgba(23, 23, 23, 0.6);
    stroke-width: 2;
    stroke-dasharray: 8, 6;
  
}
.base-horizontal-line {
  stroke: #171717;
  stroke-width: 1;
  opacity: 0.1;
}
.base-event-tooltip.base-event-tooltip {
  border-radius: 10px;
  padding: 6px 10px;
  box-shadow: 0 4px 6px 0 rgba(0,0,0,.05);
  color: #171717;
  background: #fff;
  font-size: 14px;
    font-weight: 500;
    line-height: 1.21;

    transition: 1s 2s all;
    opacity: 0;
}

.base-goal {
  transition: 1s 2s all;
  opacity: 0;

}
.base-event-weight {
  position: absolute;
  top: -30px;
  text-align: center;
  font-weight: 500;
  border-radius: 8px;
  padding: 2px 10px;


}
.base-graph--active {
  &::before {
    opacity: 1;
  }
  .base-line {
    width: 100%;
    transition: 2s all;
  }
  .base-goal {
    opacity: 1;

  }
}

.betterme-graph {
  transition: 2s all;
  &::before {
    position: absolute;
    content: '';
    bottom: -4px;
    right: 0;
    width: 14px;
    height: 14px;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 3px 5px rgba(0, 0, 0, .04), 0 5px 12px rgba(22, 42, 65, .1);
    opacity: 0;
    transition: 1s 2s all;
    z-index: 11;
  }
}
.betterme-graph--gain  {
  &::before {
    top: -4px;
    bottom: unset;

  }
}
.betterme-goal {
  transition: 1s 2s all;
  opacity: 0;

}
.betterme-graph--active {
  &::before {
    opacity: 1;
  }
  .betterme-line {
    width: 100%;
    transition: 2s all;
  }
  .betterme-goal {
    opacity: 1;

  }
}
.btn-animation {
  animation: btnAnim 1.25s cubic-bezier(.66,0,0,1) infinite;
  box-shadow: 0 0 0 0 #EA749B;
}
.animation-arrows_isAnimated__RKH3A .animation-arrows_animationTriangle1__s7j_d {
  animation: animation-arrows 1.5s ease-in-out 1s infinite;
  transform: translateX(40%);
}
.animation-arrows_isAnimated__RKH3A .animation-arrows_animationTriangle2__5PUEA {
  animation: animation-arrows1 1.5s ease-in-out 1s infinite;
  transform: translateX(-15%);
}
.animation-arrows_isAnimated__RKH3A .animation-arrows_animationTriangle3__vMb2G {
  animation: animation-arrows2 1.5s ease-in-out 1s infinite;
  transform: translateX(-65%);
}
@keyframes animation-arrows {
  0% {
    opacity: 0;
}
  75% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}
@keyframes animation-arrows1 {
  0% {
    opacity: 0;
}
  50% {
      opacity: 0;
  }
  75% {
      opacity: 1;
  }
}
@keyframes animation-arrows2 {
  0% {
    opacity: 0;
}
  25% {
      opacity: 0;
  }
  50% {
      opacity: 1;
  }
}
@keyframes btnAnim {
  100% {
    box-shadow: 0 0 0 8px transparent;
  }
}
@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes opacityArrow {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 0;
  }
}

.bage-animation-arrows .animation-arrows-1 {
  animation: bage-animation-arrows-1 1.5s ease-in-out 0.5s infinite;
}
.bage-animation-arrows .animation-arrows-2 {
  animation: bage-animation-arrows-2 1.5s ease-in-out 0.5s infinite;
}
@keyframes bage-animation-arrows-1 {
  0% {
    opacity: 0;
  }
  80% {
      opacity: 1;
  }
  100% {
      opacity: 0;
  }
}
@keyframes bage-animation-arrows-2 {
  0% {
    opacity: 0;
  }
  40% {
      opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.reviews-text {
  height: 100px;
  overflow: hidden;
}

.red-strike {
  text-decoration: line-through;
  text-decoration-color: red;
  opacity: 0.7;
}

.swiper-button-prev,
.swiper-button-next {
  &.custom-swiper-button {
    top: var(--swiper-navigation-top-offset, 28%);
    color: #000;
    width: 20px;
    height: 20px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &.custom-swiper-button::after{
    margin-left: -2px;
    font-size: 12px;
    font-weight: bold;
  }
}

.swiper-button-next {
  &.custom-swiper-button::after{
    margin-left: 2px;
  }
}


@media (min-width: 337px) {
  iframe.iframe-paypro {
      // margin-top: -25px;
  }
}

.button-plan-animation {
  position: relative;

  @keyframes animation {
    0% {
      height: 100%;
      width: 100%;
      opacity: 0.4;
   }
    50% {
      height: calc(100% + 30px);
      width: calc(100% + 30px);
      opacity: 0;
   }
    100% {
      height: 100%;
      width: 100%;
      opacity: 0;
   }
 }

  &::before, &::after{
    content:"";
    position: absolute;
    top: 50%;
    left: 50%;
    height: 100%;
    width: 100%;
    transform: translate(-50%, -50%);
    border-radius: inherit;
    background-color: inherit;
    opacity: 0.4;
    z-index: -1;
  }

  &::before{
    animation: animation 2s ease-out infinite;
  }
  &::after{
    animation: animation 2s ease-out infinite;
    animation-delay: 0.4s;
  }
}